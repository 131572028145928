<template>
  <UiSidePanelForm
    :model-value="modelValue"
    title="Lead Interests"
    description="Please add the lead's main interests so we can help you create the most accurate offer possible."
    primary-button-text="Save"
    secondary-button-text="Cancel"
    :disabled="loading"
    @update:model-value="$emit('update:modelValue', false)"
    @confirm="submit"
  >
    <form class="h-full" @submit.prevent>
      <UiInputRadio
        id="ready_to_move"
        v-model="shallowValue.ready_to_move"
        label="Ready to Move"
        :items="readyToMoveItems"
        class="mb-4"
      />
      <div class="flex flex-row items-center gap-4">
        <div class="w-8/12">
          <UiInputGap v-model="shallowBudget" label="Budget" name="Budget" placeholder="Add a budget" class="mb-4" />
        </div>
        <div class="w-4/12">
          <UiInputMenu
            v-model="shallowValue.currency_id"
            :items="currencyItems"
            label="Currency"
            name="Currency"
            placeholder="Find an option"
            class="mb-4"
            :width="200"
            search
            align-right
            input-name="code"
            input-placeholder="AED"
          />
        </div>
      </div>
      <UiInputSelect
        v-model="bedroomsIds"
        :items="bedroomsItems"
        multiple
        label="Bedrooms"
        name="Bedrooms"
        placeholder="Add number of bedrooms"
        class="mb-4"
      />
      <UiInputSelect
        v-model="locationsIds"
        :items="locationItems"
        multiple
        label="Location"
        name="Location"
        placeholder="Add locations"
        class="mb-4"
      />
      <UiInputSelect
        v-model="propertyTypesIds"
        :items="propertyTypeItems"
        multiple
        label="Property Type"
        name="Property Type"
        placeholder="Add property types"
        class="mb-4"
      />
      <UiInputGapSelect
        v-model="shallowArea"
        :items="placeAreaItems"
        label="Place area"
        name="Place area"
        placeholder="Add an area gap"
        units="Sq.Ft"
        class="mb-4"
      />
      <UiInputSelect
        v-model="amenitiesIds"
        :items="amenitiesItems"
        multiple
        label="Amenities"
        name="Amenities"
        placeholder="Add amenities"
        class="mb-4"
      />
    </form>
  </UiSidePanelForm>
</template>

<script setup lang="ts">
import sortBy from 'lodash/sortBy'
import omitBy from 'lodash/omitBy'
import { useUiStore } from '@/store/ui'
import type { InputItem, Lead } from '@/types'

const uiStore = useUiStore()

const emits = defineEmits(['update:modelValue', 'input'])

type Props = {
  modelValue: boolean
  lead: Lead
}

const props = withDefaults(defineProps<Props>(), {
  modelValue: false,
})

const readyToMoveItems = [
  { text: 'Ready to move', value: true },
  { text: 'Not ready', value: false },
]

const placeAreaItems = [
  { text: '300', value: 300 },
  { text: '400', value: 400 },
  { text: '500', value: 500 },
  { text: '600', value: 600 },
  { text: '700', value: 700 },
  { text: '800', value: 800 },
  { text: '900', value: 900 },
  { text: '1000', value: 1000 },
]

const amenitiesItems = ref<InputItem[]>([])
const currencyItems = ref<InputItem[]>([])
const locationItems = ref<InputItem[]>([])
const propertyTypeItems = ref<InputItem[]>([])
const bedroomsItems = ref<InputItem[]>([])
const loading = ref(false)

onNuxtReady(() => Promise.all([getAmenities(), getCurrencies(), getLocations(), getPropertyTypes(), getBedrooms()]))

const getAmenities = async () => {
  const amenities = await useInterests()
  amenitiesItems.value = amenities.map((a) => ({ text: a.name, value: a.id }))
}
const getCurrencies = async () => {
  const currencies = await useCurrencies()
  currencyItems.value = sortBy(
    currencies.map((c) => ({ text: c.code + ' - ' + c.name, value: c.id, ...c })),
    'name'
  )
}
const getLocations = async () => {
  const locations = await useLocations()
  const unknownItemIndex = locations.findIndex((l) => l.name === 'Unknown')
  locationItems.value = locations.map((l) => ({ text: l.name, value: l.id }))
  if (unknownItemIndex >= 0) {
    const unknownItem = locationItems.value[unknownItemIndex]
    locationItems.value.splice(unknownItemIndex, 1)
    locationItems.value.splice(0, 0, unknownItem)
  }
}

const getPropertyTypes = async () => {
  const types = await usePropertyTypes()
  propertyTypeItems.value = types.map((l) => ({ text: l.name, value: l.id }))
}

const getBedrooms = async () => {
  bedroomsItems.value = await useGetBedroomItems()
}

const shallowValue = ref<Lead>({ ...props.lead, currency_id: props.lead.currency_id || 234 })

const amenitiesIds = ref(shallowValue.value.interests?.map((i) => i.id) || [])
const locationsIds = ref(shallowValue.value.locations?.map((i) => i.id) || [])
const propertyTypesIds = ref(shallowValue.value.types?.map((i) => i.id) || [])
const bedroomsIds = ref(shallowValue.value.bedrooms?.map((i) => i.id) || [])

const shallowArea = ref([shallowValue.value.area_min || 0, shallowValue.value.area_max || 0])

const shallowBudget = ref(
  shallowValue.value.local_budget_max
    ? Number(shallowValue.value.local_budget_max)
    : shallowValue.value.budget_max
    ? Number(shallowValue.value.budget_max)
    : 0
)

watch(
  () => props.lead,
  (value) => {
    shallowValue.value = ref<Lead>({ ...value }).value
  }
)

const submit = async () => {
  const leadWithDetails = {
    ...shallowValue.value,
    area_min: shallowArea.value[0],
    area_max: shallowArea.value[1],
    budget_max: shallowBudget.value,
    bedrooms: bedroomsIds.value.map((a) => ({ id: a, name: bedroomsItems.value.find((i) => i.value === a)?.text })),
    interests: amenitiesIds.value.map((a) => ({ id: a, name: amenitiesItems.value.find((i) => i.value === a)?.text })),
    locations: locationsIds.value.map((l) => ({ id: l, name: locationItems.value.find((i) => i.value === l)?.text })),
    types: propertyTypesIds.value.map((p) => ({
      id: p,
      name: propertyTypeItems.value.find((i) => i.value === p)?.text,
    })),
  }
  loading.value = true
  const result = omitBy(leadWithDetails, (v) => v === null || v === undefined || v === 'hidden')
  try {
    const leadUpdated = await useUpdateLead(props.lead.id, result)
    uiStore.showSnackBanner('Lead updated successfully')

    emits('input', leadUpdated)
    emits('update:modelValue', false)
  } catch (error: any) {
    uiStore.showSnackBanner(error.message, 'error')
  } finally {
    loading.value = false
  }
}
</script>

<style scoped></style>
