<template>
  <!-- Text Input -->
  <div :id="name" class="relative flex w-full flex-col items-start space-y-[1px]">
    <h5 v-if="label" class="text-subhead-3 mx-4 text-black-80" :for="name">{{ label }}</h5>
    <label
      :ref="(el) => (refs[name].value = el as HTMLInputElement)"
      class="relative flex h-12 w-full min-w-[100px] flex-row items-center justify-between gap-2 px-4 py-3"
      :class="disabled ? 'cursor-default' : 'cursor-pointer'"
      @focus="disabled ? null : (isInputFocused = true)"
      @blur="disabled ? null : (isInputFocused = false)"
      @click="disabled ? null : (isInputFocused = true)"
    >
      <UiIcon
        v-if="showIcon"
        name="search"
        :class="[error ? 'text-black-60' : isInputFocused ? 'text-primary-120' : 'text-black-60']"
        class="peer/icon-prefix z-10"
      ></UiIcon>
      <input
        :id="name"
        :value="simpleInputText"
        :name="name"
        type="select"
        readonly
        autocomplete="off"
        :placeholder="placeholder"
        :disabled="disabled"
        class="peer z-10 h-[20px] flex-1 cursor-pointer border-none bg-transparent outline-none placeholder:text-sm placeholder:font-normal placeholder:leading-5"
        :class="{ 'placeholder:text-black-40': error }"
        @focus="isInputFocused = true"
      />
      <UiIcon
        name="chevron-big-filled-down"
        :class="[
          error ? 'text-black-60' : 'text-black-60 peer-focus-visible:text-primary-120',
          { 'rotate-180': isInputFocused },
        ]"
        class="peer/icon z-10 transition-all duration-200"
      />
      <div
        class="absolute left-0 z-0 h-full w-full rounded-xl border-[1.5px] border-solid outline-none transition-colors duration-200"
        :class="
          error
            ? 'border-error-100 peer-hover/icon-prefix:border-error-100 peer-hover/icon:border-error-100 peer-hover/prefix:border-error-100 peer-hover/suffix:border-error-100 peer-hover:border-error-100 '
            : 'border-black-20 hover:border-primary-50 active:border-primary-120 peer-hover/icon-prefix:border-primary-50 peer-hover/icon:border-primary-50 peer-hover/prefix:border-primary-50 peer-hover/suffix:border-primary-50 peer-hover:border-primary-50  peer-focus:border-primary-120 peer-active:border-primary-120 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05 '
        "
      ></div>
      <transition name="fade">
        <div
          v-if="isInputFocused"
          :class="placeSelectOnTop ? 'bottom-[50px]' : 'top-[50px]'"
          class="absolute left-0 z-20 flex w-full cursor-default flex-col gap-2 rounded-xl border-[1.5px] border-solid border-primary-10 bg-white p-4 shadow"
          @click.stop
        >
          <div>
            <UiInputRadio
              id="gap_options"
              v-model="gapOption"
              :items="options"
              vertical
              :dense="false"
              class="py-3"
              @update:model-value="updateOption"
            />
          </div>
          <div v-if="gapOption === 'price'">
            <UiInputTextField
              v-model="inputValue"
              type="text"
              name="Price"
              class="!h-[44px] !w-[90px] rounded-lg"
              @focusin="focusinInputHandler()"
              @focusout="focusoutInputHandler()"
            />
          </div>
        </div>
      </transition>
    </label>
    <div class="absolute -bottom-4 h-4 w-full">
      <transition name="fade" mode="out-in">
        <p v-if="error" class="text-caption-2 mx-4 flex flex-row items-center justify-start text-error-100">
          {{ error }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'

const formatter = Intl.NumberFormat('en', { notation: 'compact' })

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: number | string
  name: string
  label?: string
  placeholder?: string
  showIcon?: boolean
  units?: string
  disabled?: boolean
  error?: string
}
const props = withDefaults(defineProps<Props>(), {
  label: '',
  placeholder: '',
  units: '',
  error: '',
})

const placeSelectOnTop = ref<boolean>(false)
const isInputFocused = ref<boolean>(false)
const inputValue = ref<string | number>('0')

inputValue.value = !props.modelValue ? 0 : formatter.format(Number(props.modelValue))

const gapOption = ref<string | undefined>(
  props.modelValue ? (props.modelValue !== -1 ? 'price' : 'unknown') : undefined
)
const options = [
  { text: 'Unknown', value: 'unknown' },
  { text: 'Price', value: 'price' },
]

const focusinInputHandler = () => {
  if (props.modelValue === 0) {
    inputValue.value = ''
  } else if (props.modelValue) {
    inputValue.value = props.modelValue
  }
}

const focusoutInputHandler = () => {
  if (isNaN(Number(inputValue.value))) {
    inputValue.value = 0
  }

  emits('update:modelValue', Number(inputValue.value))
  inputValue.value = formatter.format(Number(inputValue.value))
}

const simpleInputText = computed(() => {
  if (props.modelValue === -1) return 'Unknown'
  else if (props.modelValue !== -1) {
    const budgetValue = formatter.format(Number(props.modelValue))
    return `${budgetValue} ${props.units}`
  }
})

const refs = {
  [props.name]: ref<HTMLInputElement>(),
}

onMounted(() => {
  onClickOutside(refs[props.name], () => {
    if (isInputFocused.value) {
      isInputFocused.value = false
    }
  })

  const form = refs[props.name].value?.closest('form')
  if (form) {
    placeSelectOnTop.value = form.clientHeight - Number(refs[props.name].value?.parentElement?.offsetTop) - 150 < 0
  }
})

const updateOption = (value: string) => {
  if (value === 'price') {
    inputValue.value = props.modelValue && props.modelValue !== -1 ? props.modelValue : ''
  } else if (value === 'unknown') {
    inputValue.value = -1
  }
  emits('update:modelValue', inputValue.value)
}
</script>

<style scoped></style>
