<template>
  <!-- Text Input -->
  <div :id="name" class="relative flex w-full flex-col items-start space-y-[1px]">
    <h5 v-if="label" class="text-subhead-3 mx-4 text-black-80" :for="name">{{ label }}</h5>
    <label
      :ref="(el) => (refs[name].value = el as HTMLInputElement)"
      class="relative flex h-12 w-full min-w-[100px] flex-row items-center justify-between gap-2 px-4 py-3"
      :class="disabled ? 'cursor-default' : 'cursor-pointer'"
      @focus="disabled ? null : (isInputFocused = true)"
      @blur="disabled ? null : (isInputFocused = false)"
      @click="disabled ? null : (isInputFocused = true)"
    >
      <UiIcon
        v-if="showIcon"
        name="search"
        :class="[error ? 'text-black-60' : isInputFocused ? 'text-primary-120' : 'text-black-60']"
        class="peer/icon-prefix z-10"
      ></UiIcon>
      <input
        :id="name"
        :value="simpleInputText"
        :name="name"
        type="select"
        readonly
        autocomplete="off"
        :placeholder="placeholder"
        :disabled="disabled"
        class="peer z-10 h-[20px] flex-1 cursor-pointer border-none bg-transparent outline-none placeholder:text-sm placeholder:font-normal placeholder:leading-5"
        :class="{ 'placeholder:text-black-40': error }"
        @focus="isInputFocused = true"
      />
      <UiIcon
        name="chevron-big-filled-down"
        :class="[
          error ? 'text-black-60' : 'text-black-60 peer-focus-visible:text-primary-120',
          { 'rotate-180': isInputFocused },
        ]"
        class="peer/icon z-10 transition-all duration-200"
      />
      <div
        class="absolute left-0 z-0 h-full w-full rounded-xl border-[1.5px] border-solid outline-none transition-colors duration-200"
        :class="
          error
            ? 'border-error-100 peer-hover/icon-prefix:border-error-100 peer-hover/icon:border-error-100 peer-hover/prefix:border-error-100 peer-hover/suffix:border-error-100 peer-hover:border-error-100 '
            : 'border-black-20 hover:border-primary-50 active:border-primary-120 peer-hover/icon-prefix:border-primary-50 peer-hover/icon:border-primary-50 peer-hover/prefix:border-primary-50 peer-hover/suffix:border-primary-50 peer-hover:border-primary-50  peer-focus:border-primary-120 peer-active:border-primary-120 peer-enabled:placeholder:text-black-100 peer-disabled:border-black-20 peer-disabled:bg-black-05 '
        "
      ></div>
      <transition name="fade">
        <div
          v-if="isInputFocused"
          :class="placeSelectOnTop ? 'bottom-[50px]' : 'top-[50px]'"
          class="absolute left-0 z-20 flex w-full cursor-default flex-col gap-2 rounded-xl border-[1.5px] border-solid border-primary-10 bg-white p-4 shadow"
          @click.stop
        >
          <div>
            <UiInputRadio
              id="gap_options"
              v-model="gapOption"
              :items="options"
              vertical
              :dense="false"
              class="py-3"
              @update:model-value="updateOption"
            />
          </div>
          <div v-if="gapOption === 'range' && shallowValue[0] !== -1">
            <div class="flex flex-row items-center gap-6">
              <div class="flex flex-row items-center justify-center gap-2">
                <h5 class="text-body-2">From</h5>
                <div class="w-2/3">
                  <UiInputSelect
                    v-model="shallowValue[0]"
                    :items="fromItems"
                    :name="`from_${name}`"
                    @update:model-value="selectNewToValue"
                  ></UiInputSelect>
                </div>
              </div>
              <div class="flex flex-row items-center gap-2">
                <h5 class="text-body-2">To</h5>
                <div class="w-2/3">
                  <UiInputSelect v-model="shallowValue[1]" :items="toItems" :name="`to_${name}`"></UiInputSelect>
                </div>
              </div>
            </div>
          </div>
          <div class="h-[2px] w-full bg-black-10" />
          <div class="px-3 py-2">
            <UiButtonBase id="apply" @click="apply">
              <span class="px-4">Apply</span>
            </UiButtonBase>
          </div>
        </div>
      </transition>
    </label>
    <div class="absolute -bottom-4 h-4 w-full">
      <transition name="fade" mode="out-in">
        <p v-if="error" class="text-caption-2 mx-4 flex flex-row items-center justify-start text-error-100">
          {{ error }}
        </p>
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { InputItem } from '@/types'

const emits = defineEmits(['update:modelValue'])

type Props = {
  modelValue: number[]
  items: InputItem[]
  name: string
  label?: string
  placeholder?: string
  showIcon?: boolean
  units?: string
  disabled?: boolean
  error?: string
}
const props = withDefaults(defineProps<Props>(), {
  label: '',
  placeholder: '',
  units: '',
  error: '',
})

const placeSelectOnTop = ref(false)
const isInputFocused = ref<boolean>(false)
const shallowValue = ref<number[]>(props.modelValue)

const gapOption = ref<string | undefined>(
  props.modelValue[0] ? (props.modelValue[0] === -1 ? 'unknown' : 'range') : undefined
)
const options = [
  { text: 'Unknown', value: 'unknown' },
  { text: 'Range', value: 'range' },
]

const simpleInputText = computed(() => {
  if (props.modelValue[0] === -1) return 'Unknown'
  else {
    const text = props.items
      ?.filter((i) => (props.modelValue as number[])?.includes(i.value))

      ?.map((i) => i.text)
      ?.toString()
      ?.replace(',', '-')

    if (text) {
      return text.concat(` ${props.units}`)
    }
  }
})

const fromItems = computed(() => props.items?.slice(0, -1))
const toItems = computed(() =>
  Array.isArray(shallowValue?.value)
    ? props.items.filter((i) => Number(i.value) > Number(shallowValue?.value[0]))
    : props.items
)

watch(
  () => props.modelValue,
  (value) => {
    if (value?.length) {
      shallowValue.value = JSON.parse(JSON.stringify(value))
    }
  },
  { immediate: true }
)

const refs = {
  [props.name]: ref<HTMLInputElement>(),
}

onMounted(() => {
  onClickOutside(refs[props.name], () => {
    if (isInputFocused.value) isInputFocused.value = false
    shallowValue.value = JSON.parse(JSON.stringify(props.modelValue || -1))
    gapOption.value = props.modelValue[0] ? (props.modelValue[0] === -1 ? 'unknown' : 'range') : undefined
  })

  const form = refs[props.name].value?.closest('form')
  if (form) {
    placeSelectOnTop.value = form.clientHeight - Number(refs[props.name].value?.parentElement?.offsetTop) - 150 < 0
  }
})

const updateOption = (value: string) => {
  if (value === 'range') {
    shallowValue.value =
      props.modelValue[0] && props.modelValue[0] !== -1
        ? JSON.parse(JSON.stringify(props.modelValue))
        : [fromItems.value[0].value, fromItems.value[1].value]
  } else if (value === 'unknown') {
    shallowValue.value = [-1, -1]
  }
}
const selectNewToValue = () => {
  if (shallowValue?.value[0] > shallowValue?.value[1]) shallowValue.value[1] = toItems.value[0].value
}
const apply = () => {
  isInputFocused.value = false
  emits('update:modelValue', shallowValue.value)
}
</script>

<style scoped></style>
